<template>
	<div>
		<el-dialog :draggable="true" width="85%" top="5vh" v-model="outerVisible" @close="Reset" :title="title">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="120px">
					<el-form-item label="类型">
						<span class="xx">*</span>
						<el-select clearable v-model="ruleForm.type" placeholder="请选择类型">
							<el-option label="外出务工奖补" :value="1"></el-option>
							<el-option label="外出务工交通补助" :value="2"></el-option>
							<el-option label="产业奖补" :value="3"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="封面图" v-if="ruleForm.type==1||ruleForm.type==2">
						<span class="xx">*</span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<img v-if="ruleForm.thumb" class="dialog-img" :src="ruleForm.thumb" />
							<p v-if="ruleForm.thumb" class="dele" @click="deleimg">删除</p>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl"
								:on-success="handleAvatarSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label="申请表">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 文件 -->
							<el-upload accept=".pdf,.doc,.docx,.xlm,.zip,.ppt,.xls,.xlsx,.rtf,.word" multiple
								:headers="Headers" :action="ActionUrl" :on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
							<div class="dialog-up-left">
								<div class="upload-li" v-if="imgList.length>0" v-for="(item,index) in imgList"
									:key="index">
									<div>{{item.OriginalName}}</div>
									<p @click="dele(index)">删除</p>
								</div>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="状态">
						<span class="xx">*</span>
						<el-select clearable v-model="ruleForm.status" placeholder="请选择状态">
							<el-option label="启用" :value="1"></el-option>
							<el-option label="关闭" :value="0"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<!-- 图文详情 -->
			<content ref="content" @Submitinput="Submitinput">
			</content>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">取消</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="submitForm">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/bonus";
	import content from '@/components/content.vue'
	export default {
		components: {
			content
		},
		data() {
			return {
				Headers: {
					token: ''
				},
				ActionUrl: '',
				outerVisible: false,
				title: '新增',
				ruleForm: {
					type: '',
					name: '',
					file: '',
					content: '',
					thumb: '',
					status:1
				},
				imgList: []
			}
		},
		mounted() {
			// 上转图片url
			this.Headers.token = sessionStorage.getItem('token')
			this.Headers.appid = sessionStorage.getItem('appid')
			this.Headers.nonce = sessionStorage.getItem('nonce')
			this.Headers.timestamp = sessionStorage.getItem('timestamp')
			this.Headers.sign =sessionStorage.getItem('sign')
			
			this.ActionUrl = sessionStorage.getItem('baseURL') + '/Upload/FormUpload'
		},
		methods: {
			// 删除图片
			deleimg() {
				this.ruleForm.thumb = ''
			},
			// 上转图片
			handleAvatarSuccess(res, file) {
				this.ruleForm.thumb = res.data.url
			},
			// 删除
			dele(index) {
				this.imgList.splice(index, 1)
			},
			// 上转
			UploadSuccess(res, file) {
				this.imgList.push(res.data)
			},
			// 图文详情
			Submitinput(e) {
				this.ruleForm.content = e
			},
			// 获取信息
			getinfo() {
				this.$http.post(api.info, {
						id: this.ruleForm.id
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleForm = res.data
							this.imgList = res.data.file ? JSON.parse(res.data.file) : []
							// 赋值
							this.$refs.content.setContent(res.data.content)
							delete this.ruleForm.create_time
						}
					})
			},
			// 显示隐藏
			show(val, id) {
				this.title = val
				if (id) {
					this.ruleForm.id = id
					this.getinfo()
				}
				this.outerVisible = !this.outerVisible
			},
			// 清空数据
			Reset() {
				for (let key in this.ruleForm) {
					this.ruleForm[key] = ''
				}
				this.imgList = []
				this.$refs.content.emptyContent()
				delete this.ruleForm.id
				delete this.ruleForm.delete_time
			},
			//新增/编辑
			submitForm() {
				this.ruleForm.file = this.imgList.length > 0 ? JSON.stringify(this.imgList) : ''
				this.ruleForm.name = ""
				if (this.ruleForm.type == 1) {
					this.ruleForm.name = "外出务工奖补"
				}
				if (this.ruleForm.type == 2) {
					this.ruleForm.name = "外出务工交通补助"
				}
				if (this.ruleForm.type == 3) {
					this.ruleForm.name = "产业奖补"
				}
				if (this.ruleForm.thumb === '' && this.ruleForm.type == 1 || this.ruleForm.type == 2 && this.ruleForm
					.thumb === '') {
					ElMessage({
						message: '请上传封面',
						type: 'warning',
					})
				} else if (this.ruleForm.type === '') {
					ElMessage({
						message: '请选择类型',
						type: 'warning',
					})
				} else {
					if (this.title == '新增') {
						this.add()
					} else {
						this.edit()
					}
				}
			},
			// 编辑
			edit() {
				this.$http.post(api.edit, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '编辑成功',
							})
							this.$emit('SubmitForm')
							this.outerVisible = false
						}
					})
			},
			// 新增
			add() {
				this.$http.post(api.add, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '新增成功',
							})
							this.outerVisible = false
							// 刷新列表
							this.$emit('SubmitForm')
						}
					})
			}
		}
	}
</script>
<style scoped lang="scss">
	.Draggable {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	.dialog-up {
		.top {
			font-size: 12px;
			position: absolute;
			top: 0;
			height: 20px;
			line-height: 20px;
			right: 0;

			i {
				padding: 3px;
				cursor: pointer;
				font-size: 14px;
				margin-left: 5px;
				color: #FFFFFF;
				background: rgba(0, 0, 0, 0.5);
			}
		}
	}

	.dialog-up-left {
		float: left;
		margin-left: 20px;
	}

	.upload-li {
		font-size: 12px;
		display: flex;
		height: 20px;
		line-height: 20px;
	}

	.upload-li p {
		cursor: pointer;
		color: #999999;
		margin-left: 20px;
	}

	.upload-li p:hover {
		color: #00ad79;
	}

	.dialog-title {
		margin: 40px 0;
		font-size: 16px;
	}

	.dialog-img-box {
		cursor: move;
		position: relative;
		height: 80px;
		overflow: hidden;
		width: 80px;
		margin-right: 10px;
		border: 1px solid #dcdde0;
		border-radius: 4px;
		text-align: center;
		margin-bottom: 10px;
	}

	.dialog-img-box p {
		font-size: 12px;
		width: 80px;
		position: absolute;
		bottom: 0;
		height: 20px;
		line-height: 20px;
		left: 0;
		text-align: center;
		background: rgba(0, 0, 0, 0.5);
		color: #FFFFFF;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		line-clamp: 1;
		-webkit-box-orient: vertical;
	}
</style>
